.container {
    margin-top: 1.5em;
    padding: 1em;

    border: 1px solid black;
    border-radius: 12px;

    .information {
        display: flex;
        width: 100%;
    }

    .brand-plate {
        width: 25%;
    }

    .descriptions {
        width: 70%;
    }

    .dates {
        display: flex;
        align-items: center;
        gap: 2em;
    }

    strong {
        font-size: large;
    }

    h2 a {
        text-decoration: none;
        color: black;
    }
    span {
        font-size: small;
        font-weight: lighter;
    }

    .bid {
        background-color: lightgray;
        padding: .5em;
        border-radius: 12px;
        width: fit-content;
        font-size: small;
        color: black;
    }

    p{
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
    }
}