.header {
  display: flex;
  align-items: center;
  background-color: black;
  
  color: white;
  font-weight: bold;

  height: 3.75em;
  padding-left: 1em;
  padding-right: 1em;
  
  position: sticky;
  top:0;
}

.results {
  padding: 1.75em;
}

.search-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  position: sticky;
  top:3.75em;

  padding: 1em;
  border-bottom: 1px solid black;
  background-color: white;

  .group {
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 0.25em;

    label {
      font-size: smaller;
      font-weight: bold;
    }
    input, select {
      width: 16em;
      height: 2em;
      padding-left: 0.5em;
      padding-right: 0.5em;
      border-radius: 8px;
      border-width: 1px;
    }
  }

  button {
    background-color: black;
    color: white;
    font-weight: bold;

    border: unset;
    padding: 1.5em;
    border-radius: 12px;
    cursor: pointer;
  }
}